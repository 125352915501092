body{
  background: #007bff;
  background: linear-gradient(to right, #0062E6, #33AEFF);
}

.main{
  background-color: white;
  border-radius: 20px;
  transition: transform 0.4s;
}
.main:hover{
  transform: scale(1.1)
}

li{
  list-style: none;
  margin-top: 20px;
}
.btn{
  height: 50px;
}
div:nth-child(3) > ul > li:nth-child(1){
  font-weight: bold;
}
div:nth-child(2) > ul > li:nth-child(1){
  font-weight: bold;
}
div:nth-child(3) > ul > li:nth-child(7){
  font-weight: bold;
}